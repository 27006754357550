import store from "../../store";
import * as api from ".";
import * as AuthActions from "../../store/ducks/auth/actions";
import * as UtilsActions from "../../store/ducks/utils/actions";

export async function getDre(year, balance) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`account/dre`, {
        params: { year, balance },
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACGI1) - Falha ao buscar o DRE!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getIndicators(date, type) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`account/indicator/` + type, {
        params: { date },
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACGI1) - Falha ao buscar indicadores!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function listDueDate(query) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`account/due-date`, {
        params: {
          page: query?.page,
          rows: query?.rowsPerPage,
          column: query?.orderColumn,
          orientation: query?.orderOrientation,
        },
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACGI1) - Falha ao buscar indicadores!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function listExpirationDate(query) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`account/expiration-date`, {
        params: {
          page: query?.page,
          rows: query?.rowsPerPage,
          column: query?.orderColumn,
          orientation: query?.orderOrientation,
        },
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACGI1) - Falha ao buscar indicadores!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getAccountId(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`account/${id}`, {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACGID1) - Falha ao buscar Conta!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getHistory(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`account/${id}/history`, {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACGID1) - Falha ao buscar Conta!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getAccountByBatchId(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`batches/${id}/accounts`, {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACGID1) - Falha ao buscar Conta!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getAccountByClientIdAndDate(clientId, month, year) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(
        `account/client/${clientId}/month/${month}/year/${year}`,
        {
          headers: { olsystem: token },
        }
      );
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACGID1) - Falha ao buscar Conta!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getAccountByClientId(clientId, year, month) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(
        `account/client/${clientId}/${year}/${month}`,
        {
          headers: { olsystem: token },
        }
      );
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACGID1) - Falha ao buscar Conta do cliente ${clientId}!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getAccountList(query) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`account/list/`, {
        params: {
          search: query?.search,
          page: query?.page,
          rows: query?.rowsPerPage,
          column: query?.orderColumn,
          orientation: query?.orderOrientation,
          kindOf: query.searchSelectedId ? query.searchSelectedId : 0,
          filterCategory: query?.filterCategory,
          filterStatus: query?.filterStatus,
          filterClient: query?.filterClient,
          typeFilterDate: query?.typeFilterDate,
          startDate: query?.startDate,
          endDate: query?.endDate,
          headerFilter: query?.headerFilter,
        },
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACGL1) - Falha ao buscar contas!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getAccountSummary(query) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`account/list/summary`, {
        params: {
          search: query?.search,
          kindOf: query.searchSelectedId,
          filterCategory: query?.filterCategory,
          filterStatus: query?.filterStatus,
          filterClient: query?.filterClient,
          typeFilterDate: query?.typeFilterDate,
          startDate: query?.startDate,
          endDate: query?.endDate,
          headerFilter: query?.headerFilter,
        },
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACGL1) - Falha ao buscar contas!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getAccountReceived(query) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`account/received`, {
        params: {
          page: query?.page,
          rows: query?.rowsPerPage,
          column: query?.orderColumn,
          orientation: query?.orderOrientation,
          dataSelected: query?.dataSelected,
        },
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACGL1) - Falha ao buscar contas!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getCategoryList(query) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`accountCategories/list/`, {
        params: {
          search: query.search,
          rows: query.rowsPerPage,
          page: query.page,
          column: query.orderColumn,
          orientation: query.orderOrientation,
        },
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACCGL1) - Falha ao buscar categorias!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getAuxAccountCategories() {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`accountCategories/aux`, {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACCGL1) - Falha ao buscar categorias!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getDomains() {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`domains/list`, {
        params: {
          domain: "PAYMENTS_METHOD",
        },
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACCGL1) - Falha ao buscar categorias!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function createAccount(createData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const response = await api.apiPost("account/create", createData, {
        headers: { olsystem: token },
      });
      if (!response.error) {
        return { data: response.data, error: false };
      } else {
        handleError(response);
        return { error: true };
      }
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACCA1) - Falha ao criar fatura!`
        )
      );
      return { error: true };
    }
  } else {
    store.dispatch(AuthActions.unauthenticated());
    return { error: true };
  }
}

export async function updateAccountReceive(updateData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPut(
        "account/update/receive/" + updateData.id,
        updateData,
        { headers: { olsystem: token } }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUAC1) - Falha ao atualizar conta! ${updateData.id}`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}
export async function cancelAccount(updateData, type) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPut(
        `account/cancel/${updateData.id}`,
        updateData,
        { headers: { olsystem: token } }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUAC1) - Falha ao atualizar conta! ${updateData.id}`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function updateAccountsBulk(accounts) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPatch("account/update/bulk", accounts, {
        headers: { olsystem: token },
      });
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUAC1) - Falha ao atualizar contas! `
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function updateFieldsAccountReceive(id, updates) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPatch("account/update/receive/" + id, updates, {
        headers: { olsystem: token },
      });
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUAC1) - Falha ao atualizar conta! ${id}`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function updateAccountPayment(updateData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPut(
        "account/update/payment/" + updateData.id,
        updateData,
        { headers: { olsystem: token } }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUAC1) - Falha ao atualizar conta! ${updateData.id}`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function excludeAccount(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiDelete(`account/delete/` + id, {
        headers: { olsystem: token },
      });
      if (!data.error)
        return store.dispatch(
          UtilsActions.enqueueSnackbar(
            "Success",
            `(#ACDA2) - Conta apagada com Sucesso!`
          )
        );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACDA1) - Falha ao apagar conta!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function getAccountSugest(query) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(
        `account/sugest/${query.dueDate}/${query.clientId}`,
        {
          params: {
            dueDate: query.dueDate,
            clientId: query.clientId,
          },
          headers: { olsystem: token },
        }
      );
      if (!data.error) return data;
      else handleError(data);
    } catch (err) {
      console.log(err);
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACCA1) - Falha ao buscar Sugestões de Combinação!"
        )
      );
    }
  } else {
    store.dispatch(AuthActions.unauthenticated());
    return { error: true };
  }
}

export async function getAccountSugestByClient(clientId) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`account/client/sugest/${clientId}`, {
        headers: { olsystem: token },
      });
      if (!data.error) return data.data;
      else handleError(data);
    } catch (err) {
      console.log(err);
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACCA1) - Falha ao buscar Sugestões de Combinação do Cliente!"
        )
      );
    }
  } else {
    store.dispatch(AuthActions.unauthenticated());
    return { error: true };
  }
}

export async function combineAccounts(id, childrens) {
  console.log(id);
  console.log(childrens);
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPut(
        `account/${id}/combine`,
        { childrens: childrens },
        {
          headers: { olsystem: token },
        }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUAC1) - Falha ao combinar contas!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function decombineAccounts(childrens) {
  console.log(childrens);
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPut(
        "account/decombine",
        { childrens: childrens },
        {
          headers: { olsystem: token },
        }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUAC1) - Falha ao combinar contas!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function updateAccountsDiscountTaxBulk({
  accounts,
  tax = 0,
  discount = 0,
  dueDate,
}) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPatch(
        "account/update/bulk/discount/tax",
        { accounts: accounts, tax: tax, discount: discount, dueDate: dueDate },
        {
          headers: { olsystem: token },
        }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUAC1) - Falha ao atualizar contas! `
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function renegotiate({ createData, accounts }) {
  console.log(createData, accounts);
  const state = store.getState();
  const token = state.auth.token;

  if (!token) {
    store.dispatch(AuthActions.unauthenticated());
    return { error: true };
  }

  try {
    const response = await api.apiPost(
      "account/renegotiate/receive",
      { createData: createData, accounts: accounts },
      {
        headers: { olsystem: token },
      }
    );

    if (response.error) {
      handleError(response);
      return { error: true };
    }

    return { error: false, account_id: response.account_id };
  } catch (err) {
    store.dispatch(
      UtilsActions.enqueueSnackbar(
        "error",
        `(#ACUAC1) - Falha ao renegociar contas!`
      )
    );
    return { error: true };
  }
}

export async function getRenegociate({ accountId, renegociateAccountId }) {
  console.log(accountId, renegociateAccountId);
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(
        `account/renegotiate/show`,

        {
          params: {
            accountId: accountId,
            renegociateAccountId: renegociateAccountId,
          },
          headers: { olsystem: token },
        }
      );
      if (!data.error) {
        return data.data;
      } else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACGID1) - Falha ao buscar Conta!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}
// -------------------------------------------------- LOCAL FUNCTIONS
function handleError(data) {
  if (data.status === 401) store.dispatch(AuthActions.unauthenticated());
  else if (data.status === 403) store.dispatch(AuthActions.unauthorized());
  else
    data.msg.map((m) =>
      store.dispatch(UtilsActions.enqueueSnackbar("error", m))
    );
}
