import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { PDFViewer } from "@react-pdf/renderer";
import * as QuotationsApi from "@api/backend/quotation";
import styles from "@styles/pdf";
import { useParams } from "react-router-dom";
import Doc from "../components/QuotationFilePDF";

const QuotationFile = () => {
  const { quotationId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setData(await QuotationsApi.getSingleQuotation(quotationId));
    }
    fetchData();
  }, [quotationId]);

  return (
    data && (
      <>
        <Helmet>
          <title>On.Loc | Proposta Visualizar</title>
        </Helmet>
        <PDFViewer style={styles.pdfViewer}>
          <Doc data={data} />
        </PDFViewer>
      </>
    )
  );
};

export default QuotationFile;
