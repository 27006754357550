import { makeStyles } from "@material-ui/core";
import {
  colorWhatsApp,
  colorSuccess,
  colorSuccessLight,
  colorSuccessDark,
  colorWarning,
  colorWarningLight,
  colorWarningDark,
  colorCancel,
  colorCancelLight,
  colorCancelDark,
  colorInfoLight,
  colorInfo,
  colorGreyLight,
  colorGrey,
  colorText,
  colorPrimary,
  colorWhite,
  colorCritical,
  colorCriticalLight,
  colorSecondary,
} from "../config/colors";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  dailyMessage: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    alignItems: "center",
    flexDirection: "row",
    background: colorPrimary,
    color: colorWhite,
    position: "relative",
    width: "100%",
  },
  dailyMessageVariant: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    alignItems: "center",
    flexDirection: "row",
    background: colorWhite,
    position: "relative",
    width: "100%",
    height: "100%",
  },
  box: {
    marginBottom: theme.spacing(2),
    display: "flex",
    width: "inherit",
    alignItems: "center",
    justifyContent: "space-between",
  },
  icon: {
    width: "1.5em",
    fontSize: 15,
  },
  iconHappy: {
    width: "1.5em",
    fontSize: 15,
    color: colorWhite,
  },
  fontSize12: {
    fontSize: 12,
  },
  buttonAdd: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 10,
  },
  gridPadding: {
    padding: "10px",
  },
  gridPaddingDialogTitleAction: {
    padding: "10px 20px",
  },
  gridButtonPadding: {
    padding: "10px",
    marginBottom: "10px",
  },
  marginContainer: {
    margin: "10px 0",
  },
  marginContainerMedium: {
    marginBottom: "20px",
  },
  marginContainerLarge: {
    marginBottom: "40px",
  },
  whatsapp: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  iconWhats: {
    color: colorWhatsApp,
    fontSize: 12,
    marginLeft: 4,
    cursor: "pointer",
  },
  iconSmall: {
    color: colorText,
    fontSize: 12,
    marginLeft: 4,
    cursor: "pointer",
  },
  cell: {
    marginTop: 1,
    textDecoration: "none",
    color: colorWhatsApp,
  },
  emailAlign: {
    display: "flex",
    alignItems: "baseline",
  },
  iconButtonMinWidht: {
    minWidth: 40,
    minHeight: 30,
    textAlign: "center",
  },
  iconButtonHappy: {
    minWidth: 40,
    minHeight: 30,
    textAlign: "center",
    color: colorPrimary,
  },
  infoStyle: {
    color: theme.palette.getContrastText(colorInfoLight) + " !important",
    backgroundColor: colorInfoLight + " !important",
    "&:hover": {
      backgroundColor: colorInfo + " !important",
    },
  },
  successStyle: {
    color: theme.palette.getContrastText(colorSuccessLight) + " !important",
    backgroundColor: colorSuccessLight + " !important",
    "&:hover": {
      backgroundColor: colorSuccess + " !important",
    },
  },
  warningStyle: {
    color: theme.palette.getContrastText(colorWarningLight) + " !important",
    backgroundColor: colorWarningLight + " !important",
    "&:hover": {
      backgroundColor: colorWarning + " !important",
    },
  },
  relocationStyle: {
    color: theme.palette.getContrastText("#ad47ac") + " !important",
    backgroundColor: "#ad47ac" + " !important",
    // color: colorWhite,
    "&:hover": {
      backgroundColor: "#851d86" + " !important",
    },
  },
  cancelStyle: {
    color: theme.palette.getContrastText(colorCancelLight) + " !important",
    backgroundColor: colorCancelLight + " !important",
    // color: colorWhite,
    "&:hover": {
      backgroundColor: colorCancel + " !important",
    },
  },
  criticalStyle: {
    color: theme.palette.getContrastText(colorCancelLight) + " !important",
    backgroundColor: colorCriticalLight + " !important",
    // color: colorWhite,
    "&:hover": {
      backgroundColor: colorCritical + " !important",
    },
  },
  purchaseStyle: {
    color: theme.palette.getContrastText(colorGreyLight),
    backgroundColor: colorGreyLight,
    // color: colorWhite,
    "&:hover": {
      backgroundColor: colorGrey,
    },
  },
  totalValueSyle: {
    backgroundColor: colorWhite,
    "&:hover": {
      // color: colorWhite,
      backgroundColor: colorGreyLight,
    },
  },
  gridSwitchPadding: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  searchDivider: {
    height: 28,
    margin: 4,
  },
  searchIcon: {
    margin: 4,
    color: "rgba(0, 0, 0, 0.54)",
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  tableCell: {
    padding: theme.spacing(1, 2),
    height: "47px",
  },
  tableCellV2: {
    borderBottom: "0 !important",
    padding: "8px",
  },
  rightBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  noMargin: {
    margin: 0,
  },
  noPadding: {
    padding: 0,
  },
  flexAlign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  buttonGreen: {
    color: theme.palette.getContrastText(colorSuccess),
    backgroundColor: colorSuccess,
    "&:hover": {
      backgroundColor: colorSuccessDark,
    },
  },
  buttonRed: {
    color: theme.palette.getContrastText(colorCancel),
    backgroundColor: colorCancel,
    "&:hover": {
      backgroundColor: colorCancelDark,
    },
  },
  buttonYellow: {
    color: theme.palette.getContrastText(colorWarning),
    backgroundColor: colorWarning,
    "&:hover": {
      backgroundColor: colorWarningDark,
    },
  },
  description: {
    padding: theme.spacing(2),
    textAlign: "left",
  },
  appBar: {
    position: "relative",
  },
  dialogtitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogtitlebox: {
    background: colorPrimary,
    color: colorWhite,
  },
  dialogtitletext: {
    fontWeight: "300 !important",
  },
  dialogContent: {
    paddingTop: "40px",
    backgroundColor: "#eee",
  },
  field: {
    backgroundColor: "#eee",
  },
  boxPayment: {
    top: 0,
    position: "fixed",
  },
}));

export default useStyles;
