import { colors } from "@material-ui/core";

// colors.red;
// colors.pink;
// colors.purple;
// colors.deepPurple;
// colors.indigo;
// colors.blue;
// colors.lightBlue;
// colors.cyan;
// colors.teal;
// colors.green;
// colors.lightGreen;
// colors.lime;
// colors.yellow;
// colors.amber;
// colors.orange;
// colors.deepOrange;
// colors.brown;
// colors.grey;
// colors.blueGrey;

export const colorSuccess = colors.green[600];
export const colorCancel = colors.red[600];
export const colorWarning = colors.orange[600];
export const colorInfo = colors.lightBlue[600];
export const colorCritical = colors.deepPurple[600];

export const colorSuccessDark = colors.green[800];
export const colorCancelDark = colors.red[800];
export const colorWarningDark = colors.orange[800];
export const colorInfoDark = colors.lightBlue[800];
export const colorCriticalDark = colors.deepPurple[800];

export const colorSuccessLight = colors.green[300];
export const colorCancelLight = colors.red[300];
export const colorWarningLight = colors.orange[300];
export const colorInfoLight = colors.lightBlue[300];
export const colorCriticalLight = colors.deepPurple[300];

export const colorPrimary = colors.indigo[500];
export const colorPrimaryLight = colors.indigo[300];
export const colorSecondaryLight = colors.grey[100];
export const colorSecondary = colors.grey[300];
export const colorSecondaryDark = colors.grey[500];

export const colorWhite = "#fff";
export const colorBlack = "#000";

export const colorGreenLight = colors.green[100];
export const colorGreen = colors.green[500];
export const colorGreyLight = colors.grey[200];
export const colorGrey = colors.grey[500];
export const colorPurpleLight = colors.deepPurple[300];

export const colorAutoReloadDark = colors.grey[600];
export const colorAutoReloadLight = colors.grey[400];

export const colorWhatsApp = "#25d366";
export const colorText = "rgba(0, 0, 0, 0.87)";

export const colorBatchServiceOrderCode = {
  DP_COMERCIAL: colors.purple[500],
  DP_TECNICO: colors.deepPurple[500],
  S_ESTOQUE: colors.indigo[500],
  MONTAGEM: colors.blue[500],
  QUALIDADE: colors.lightBlue[500],
  ENTREGA: colors.cyan[500],
};
