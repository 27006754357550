import React, { useEffect, useState } from "react";
import clsx from "clsx";

import {
  Table,
  TableBody,
  Paper,
  Divider,
  InputBase,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
  withStyles,
  Typography,
  Button,
  Grid,
  Chip,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Icon,
  Menu,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Pagination } from "@material-ui/lab";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons";

import {
  DEFAULT_COUNT,
  DEFAULT_QUERY,
  DEFAULT_ROWS_PER_PAGE_ARRAY,
} from "../config/constants";
import useStyles from "../styles";

const internalStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-root": {
      marginTop: 4, // remove margin
      marginBottom: 4, // remove margin
      paddingTop: 4, // remove padding if necessary
      paddingBottom: 4, // remove padding if necessary
    },
    "& .MuiTableRow-root:nth-child(even)": {
      backgroundColor: "#e8f2ff73", // altere para a cor desejada
    },
    "& .MuiTableRow-root:nth-child(odd)": {
      backgroundColor: "#fff", // altere para a cor desejada
    },
  },
  container: {
    // to use fixed header
    // maxHeight: 'calc(100vh - 304px)',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  borderTop: { marginTop: theme.spacing(2) },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
  },
  sortSpotlight: { backgroundColor: "rgba(63,81,181,0.04)" },
  // root: { overflowX: 'initial', }
}));

// STRIPED BG
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Row = ({ columns, orderColumn, row, collapsable }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();
  const internalClasses = internalStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return [
    <StyledTableRow key={row} hover role="checkbox">
      {collapsable && (
        <TableCell>
          {row.collapsable && (
            <IconButton
              style={{ padding: "0 3px" }}
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <Icon className={clsx("fas fa-chevron-up", classes.icon)} />
              ) : (
                <Icon className={clsx("fas fa-chevron-down", classes.icon)} />
              )}
            </IconButton>
          )}
        </TableCell>
      )}
      {columns.map((column) => {
        const value = row[column.key];
        return (
          <React.Fragment key={`column-${column.key}`}>
            <TableCell
              key={column.key}
              align={column.align}
              className={clsx(
                classes.tableCell,
                orderColumn === column.key
                  ? internalClasses.sortSpotlight
                  : null
              )}
              // style={{ padding: '8px 16px' }}
            >
              {column.key === "actions" && column.version === 2 ? (
                <React.Fragment key={`context-menu-${row.id}`}>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    key={`menu-button-${row.id}`}
                  >
                    <MenuIcon key={`menu-icon-button-${row.id}`} />
                  </Button>
                  <Menu
                    id="simple-menu"
                    key={`menu-${row.id}`}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {value}
                  </Menu>
                </React.Fragment>
              ) : column.format && typeof value === "number" ? (
                column.format(value)
              ) : (
                value
              )}
            </TableCell>
          </React.Fragment>
        );
      })}
    </StyledTableRow>,
    collapsable && row.collapsable && open && (
      <TableRow>
        <TableCell
          // style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row["collapsable"]}
          </Collapse>
        </TableCell>
      </TableRow>
    ),
  ];
};

const TableCustom = ({
  placeholder = "Pesquisar",
  columns = [],
  rows = [],
  counts = DEFAULT_COUNT,
  collapsable = false,

  notSelectedOption = true,
  filters = null,
  filtersDefaultShow = false,
  selectArrayLabel = "",
  selectArray = null,
  filterDefaultOption = null,

  defaultQuery = DEFAULT_QUERY,
  query = DEFAULT_QUERY,
  setQuery = null,
  resetQuery = null,

  showSearch = true,
  showCounts = true,
  showCountOnlyFiltered = false,
  showChangeRowsPerPage = true,
  showPagination = true,
}) => {
  const classes = useStyles();
  const internalClasses = internalStyles();
  const [search, setSearch] = useState("");
  const [searchSelectedId, setSearchSelectedId] = useState(filterDefaultOption);
  const [showFilter, setShowFilter] = useState(filtersDefaultShow);
  counts = { ...DEFAULT_COUNT, ...counts };

  const handleSearchInput = (event) => {
    setSearch(event.target.value);
  };
  const handleSearchSelectedIdInput = (event) => {
    setSearchSelectedId(event.target.value);
    setQuery((q) => ({ ...q, searchSelectedId: event.target.value, page: 1 }));
  };
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setQuery((q) => ({ ...q, search, searchSelectedId, page: 1 }));
  };
  const handleShowFilter = (event) => {
    event.preventDefault();
    setShowFilter((showFilter) => !showFilter);
  };
  const handleSearchClear = () => {
    // if(search !== '' || searchSelectedId !== 0){
    setSearch("");
    setSearchSelectedId(filterDefaultOption);
    //     setQuery(q => ({ ...q, search: '', searchSelectedId: 0, page: 1 }));
    // }
    setQuery({ ...defaultQuery });
  };
  const handleChangePage = (event, newPage) => {
    setQuery((q) => ({ ...q, page: newPage }));
  };
  const handleChangeRowsPerPage = (event) => {
    setQuery((q) => ({ ...q, rowsPerPage: event.target.value, page: 1 }));
  };
  const handleChangeOrder = (column) =>
    setQuery((q) =>
      q.orderColumn === column && q.orderOrientation === "asc"
        ? { ...q, orderColumn: column, orderOrientation: "desc" }
        : { ...q, orderColumn: column, orderOrientation: "asc" }
    );

  useEffect(() => {
    if (query.searchSelectedId !== searchSelectedId) {
      setSearchSelectedId(query.searchSelectedId);
    }
  }, [query]);

  return (
    <>
      <Grid
        component="form"
        onSubmit={handleSearchSubmit}
        container
        spacing={2}
        alignItems="center"
      >
        {showSearch && (
          <Grid item xs>
            <Paper className={classes.search}>
              <SearchIcon className={classes.searchIcon} />
              <Divider
                className={classes.searchDivider}
                orientation="vertical"
              />
              <InputBase
                className={classes.searchInput}
                placeholder={placeholder}
                onChange={handleSearchInput}
                value={search}
                autoFocus
              />
              <Divider
                className={classes.searchDivider}
                orientation="vertical"
              />
              <Button type="submit">Buscar</Button>
              {filters && (
                <Tooltip title="Exibir Filtros">
                  <IconButton onClick={handleShowFilter}>
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Divider
                className={classes.searchDivider}
                orientation="vertical"
              />
              <Tooltip title="Limpar Filtros">
                <IconButton
                  onClick={resetQuery ? resetQuery : handleSearchClear}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Paper>
          </Grid>
        )}
        {selectArray && (
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="select-search">{selectArrayLabel}</InputLabel>
              <Select
                required
                name="selectedSearchId"
                labelId="select-search"
                label={selectArrayLabel}
                onChange={handleSearchSelectedIdInput}
                value={searchSelectedId === null ? 0 : searchSelectedId}
              >
                {notSelectedOption === true && (
                  <MenuItem
                    value={
                      filterDefaultOption !== null ? filterDefaultOption : 0
                    }
                  >
                    <em>Não Selecionado</em>
                  </MenuItem>
                )}
                {selectArray.map((selectItem) => (
                  <MenuItem key={selectItem.id} value={selectItem.id}>
                    {selectItem.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {showFilter && (
          <Grid item xs={12}>
            {filters}
          </Grid>
        )}
        <Grid item xs={12}>
          <TableContainer classes={{ root: internalClasses.root }}>
            {/* <Table stickyHeader aria-label="sticky table"> */}
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {collapsable && (
                    <TableCell style={{ width: "62px" }}>&nbsp;</TableCell>
                  )}
                  {columns.map((column) => {
                    return (
                      !column.hidden && (
                        <TableCell
                          key={column.key}
                          align={column.align}
                          style={column.style}
                          className={
                            query.orderColumn === column.key
                              ? internalClasses.sortSpotlight
                              : null
                          }
                          onClick={() =>
                            column.sort ? handleChangeOrder(column.key) : null
                          }
                        >
                          <TableSortLabel
                            active={query.orderColumn === column.key}
                            direction={query.orderOrientation}
                            hideSortIcon={!column.sort}
                          >
                            <Typography>{column.label}</Typography>
                          </TableSortLabel>
                        </TableCell>
                      )
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <Row
                      key={`row-${index}`}
                      columns={columns}
                      orderColumn={query.orderColumn}
                      row={row}
                      collapsable={collapsable}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {(showCounts || showChangeRowsPerPage || showPagination) && (
          <Grid item xs={12}>
            <Grid container alignItems="center">
              {(showCounts || showChangeRowsPerPage) && (
                <Grid item xs={12} md={6}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    {showCounts && !showCountOnlyFiltered && (
                      <Grid item>
                        {query.rowsPerPage !== -1 && (
                          <Tooltip title="Exibindo / Total Pesquisado / Total">
                            <Chip
                              style={{ borderRadius: "5px" }}
                              color="secondary"
                              label={
                                "Qtde " +
                                counts.showing +
                                " / " +
                                counts.filtered +
                                " / " +
                                counts.total
                              }
                            />
                          </Tooltip>
                        )}
                        {query.rowsPerPage === -1 && (
                          <Tooltip title="Total Pesquisado / Total">
                            <Chip
                              style={{ borderRadius: "5px" }}
                              color="secondary"
                              label={
                                "Qtde " + counts.filtered + " / " + counts.total
                              }
                            />
                          </Tooltip>
                        )}
                      </Grid>
                    )}
                    {showCounts && showCountOnlyFiltered && (
                      <Grid item>
                        <Tooltip title="Total">
                          <Chip
                            style={{ borderRadius: "5px" }}
                            color="secondary"
                            label={"Qtde " + counts.filtered}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                    {showChangeRowsPerPage && (
                      <Grid item>
                        <Select
                          required
                          name="rowsPerPage"
                          onChange={handleChangeRowsPerPage}
                          value={query.rowsPerPage}
                        >
                          {DEFAULT_ROWS_PER_PAGE_ARRAY.map((rpp) => (
                            <MenuItem key={rpp.id} value={rpp.id}>
                              {rpp.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {showPagination && (
                <Grid item xs={12} md={6}>
                  <Pagination
                    className={internalClasses.pagination}
                    count={
                      query.rowsPerPage !== -1
                        ? Math.ceil(counts.filtered / query.rowsPerPage)
                        : 1
                    }
                    page={query.page}
                    siblingCount={1}
                    boundaryCount={1}
                    color="primary"
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default TableCustom;
