import {
  Grid,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  Divider,
  Link,
} from "@material-ui/core";
import * as AccountApi from "@api/backend/account";
import useStyles from "@styles";
import TableCustom from "../../../components/TableCustom";
import * as Helper from "../../../utils/helper";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useDialogAccountDetail } from "./AccountDetail";

const RenegotiateHistory = ({
  accountId,
  renegociateAccountId = null,
  account = account,
  showDialog,
  setShowDialog,
}) => {
  const { openDialog, forceUpdate } = useDialogAccountDetail();

  const [rows, setRows] = useState([]);
  const [childrenRows, setChildrenRows] = useState([]);
  const classes = useStyles();

  useEffect(async () => {
    console.log("aaaa", account);
    const array = await AccountApi.getRenegociate({
      accountId: accountId,
      renegociateAccountId: renegociateAccountId,
    });

    // Processando os dados para a tabela de "Contas Renegociadas"
    const childrenData = array.childrens.map((child) => ({
      due_date: format(
        new Date(child.account.account_receife.due_date),
        "dd/MM/yyyy"
      ),
      description: (
        <Link
          href="#"
          onClick={(event) => {
            openDialog(child.account.account_receife.account_id, "recebimento");
          }}
        >
          {child.account.account_receife.description}
        </Link>
      ),
      price:
        "R$ " + Helper.numberToReal(child.account.account_receife.price ?? "0"),
      discount:
        "R$ " +
        Helper.numberToReal(child.account.account_receife.discount ?? "0"),
      tax:
        "R$ " + Helper.numberToReal(child.account.account_receife.tax ?? "0"),
      total:
        "R$ " + Helper.numberToReal(child.account.account_receife.total ?? "0"),
    }));

    // Processando os dados para a tabela de "Nova Conta"
    const fatherData = [
      {
        due_date: format(
          new Date(array.father.account.account_receife.due_date),
          "dd/MM/yyyy"
        ),
        description: (
          <Link
            href="#"
            onClick={(event) => {
              openDialog(
                array.father.account.account_receife.account_id,
                "recebimento"
              );
            }}
          >
            {array.father.account.account_receife.description}
          </Link>
        ),
        price:
          "R$ " +
          Helper.numberToReal(
            array.father.account.account_receife.price ?? "0"
          ),
        discount:
          "R$ " +
          Helper.numberToReal(
            array.father.account.account_receife.discount ?? "0"
          ),
        tax:
          "R$ " +
          Helper.numberToReal(array.father.account.account_receife.tax ?? "0"),
        total:
          "R$ " +
          Helper.numberToReal(
            array.father.account.account_receife.total ?? "0"
          ),
      },
    ];

    setChildrenRows(childrenData);
    setRows(fatherData);
  }, [showDialog, accountId, renegociateAccountId]);

  const COLUMNS = [
    {
      key: "due_date",
      column: "due_date",
      label: "Vencimento",
      align: "center",
      sort: false,
    },
    {
      key: "description",
      column: "description",
      label: "Descrição",
      align: "left",
    },
    {
      key: "price",
      column: "price",
      label: "Valor Original",
      align: "right",
      sort: false,
    },
    {
      key: "discount",
      column: "discount",
      label: "Desconto",
      align: "right",
      sort: false,
    },
    {
      key: "tax",
      column: "tax",
      label: "Juros/Multa",
      align: "right",
      sort: false,
    },
    {
      key: "total",
      column: "total",
      label: "Valor Pago",
      align: "right",
      sort: false,
    },
  ];

  return (
    showDialog && (
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">Renegociação</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.gridPadding}>
                Contas Renegociadas
              </Grid>
              <Grid item md={12} className={classes.gridPadding}>
                <TableCustom
                  columns={COLUMNS}
                  filtersDefaultShow={false}
                  rows={childrenRows}
                  showSearch={false}
                  showCounts={false}
                  showChangeRowsPerPage={false}
                  showPagination={false}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridPadding}>
                Nova Conta
              </Grid>
              <Grid item md={12} className={classes.gridPadding}>
                <TableCustom
                  columns={COLUMNS}
                  filtersDefaultShow={false}
                  rows={rows}
                  showSearch={false}
                  showCounts={false}
                  showChangeRowsPerPage={false}
                  showPagination={false}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )
  );
};

export default RenegotiateHistory;
