import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  Button,
  DialogContent,
  DialogContentText,
  MenuItem,
  TextField,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import * as AccountApi from "@api/backend/account";
import * as DomainApi from "@api/backend/domain";
import * as yup from "yup";
import useStyles from "@styles";
import { useDispatch } from "react-redux";
import * as UtilsActions from "@store/ducks/utils/actions";
import { useEffect, useState } from "react";
import { ACCOUNT_STATUS } from "../../../config/constants";

const CancelAccount = (data) => {
  const { accountId, close, open, closeDialog } = data;
  const [listReasons, setListReasons] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    cancel_reason: yup
      .string()
      .required("Motivo de Cancelamento é obrigatório"),
    cancel_observation: yup
      .string()
      .max(
        150,
        "A observação de cancelamento deve ter no máximo 150 caracteres"
      )
      .when("cancelReason", {
        is: (val) => val === "OU",
        then: (schema) =>
          schema.required("Observação de Cancelamento é obrigatório"),
      }),
  });

  const form = useForm({
    reValidateMode: "onChange",
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { control, getValues, handleSubmit, reset, formState } = form;

  const handleCancel = () => {
    close(false);
  };
  const onSubmit = async () => {
    dispatch(UtilsActions.setLoading());

    const data = getValues();

    if (data) {
      dispatch(UtilsActions.setLoading());
      await AccountApi.cancelAccount({
        id: accountId,
        data: {
          ...data,
          received: ACCOUNT_STATUS.CANCELED,
        },
      });
      close(false);
      closeDialog();
      dispatch(UtilsActions.resetLoading());
      dispatch(
        UtilsActions.enqueueSnackbar("success", `Conta cancelada com sucesso!`)
      );
    }
  };

  useEffect(() => {
    const fetchReasons = async () => {
      const response = await DomainApi.getDomains("CANCEL_ACCOUNT_REASON");
      setListReasons(response.data);
    };

    fetchReasons();
  }, [dispatch]);

  return (
    open && (
      <>
        <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle id="alert-dialog-title">
              {"Cancelar conta"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.gridPadding}>
                    Por qual motivo deseja cancelar esta conta?
                  </Grid>

                  <Grid item xs={12} className={classes.gridPadding}>
                    <FormControl
                      required={true}
                      fullWidth={true}
                      variant="outlined"
                    >
                      <Controller
                        name="cancel_reason"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <InputLabel id="select-cancelReason">
                              Motivo de Cancelamento
                            </InputLabel>
                            <Select
                              required
                              name="cancel_reason"
                              labelId="select-cancelReason"
                              label="Categoria"
                              onChange={onChange}
                              helperText={error ? error.message : null}
                              value={value}
                            >
                              <MenuItem value="0">
                                <em>Não Selecionado</em>
                              </MenuItem>
                              {listReasons &&
                                listReasons.map((data) => (
                                  <MenuItem
                                    key={data.identifier}
                                    value={data.identifier}
                                  >
                                    {data.description}
                                  </MenuItem>
                                ))}
                            </Select>
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} className={classes.gridPadding}>
                    <FormControl required={true} fullWidth={true}>
                      <Controller
                        name="cancel_observation"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            id="cancel_observation"
                            name="cancel_observation"
                            variant="outlined"
                            label="Detalhe do cancelamento"
                            fullWidth
                            autoFocus
                            helperText={error ? error.message : null}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCancel}
                variant="contained"
                color="secondary"
                fullWidth
              >
                Ignorar
              </Button>
              <Button
                variant="contained"
                classes={{
                  root: clsx(classes.buttonRed),
                }}
                fullWidth
                type="submit"
                autoFocus
                disabled={formState.isSubmitSuccessful || !formState.isValid}
              >
                Cancelar Conta
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    )
  );
};

export default CancelAccount;
